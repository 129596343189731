/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { ChangeLogEntry } from './useChangeLogs';
import { paletteSwitch } from '../../../../themes/palette';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/pro-solid-svg-icons/faUserShield';
import clsx from 'clsx';
import { Spinner } from '../../../../components/spinner/Spinner';
import { LoadMoreIntersectionButton } from '../../../dataproduct/pagination/LoadMoreIntersectionButton';
import { parseISO } from 'date-fns';
import { TextWithTooltip } from '../TextWithTooltip';
import { UserAndRolesTableCell } from '../UserAndRolesTableCell';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { useTableStyles } from '../../../../components/table/useTableStyles';
import { TableHeaderCell } from '../../../../components/table/TableHeaderCell';

interface ChangesTableProps {
    logs: ChangeLogEntry[];
    hasMoreRecords: boolean;
    isFetchingPage: boolean;
    fetchMore: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
    container: {
        maxHeight: 'calc(100vh - 22.5rem)',
    },
    truncated: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    adminOptionIcon: {
        marginRight: '0.25rem',
    },
    entitySpecified: {
        fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    },
    tableHeadCell: {
        fontWeight: 600,
        letterSpacing: '0.5px',
        fontFamily: theme.typography.fontFamily,
    },
    link: {
        color: paletteSwitch(theme).purple,
        letterSpacing: '0.5px',
    },
}));

export const ChangesLogTable: FunctionComponent<ChangesTableProps> = ({
    logs,
    hasMoreRecords,
    isFetchingPage,
    fetchMore,
}) => {
    const classes = useStyles();
    const tableClasses = useTableStyles();
    return (
        <TableContainer className={clsx(tableClasses.table, classes.container)}>
            <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell widthInPercentage={10}>Operation</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={10}>Entity kind</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={15}>What changed</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={18}>Entity</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={10}>Role affected</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={10}>User (Role)</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={15}>Time of change</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={12}>Query ID</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs.map((log) => (
                        <TableRow hover key={log.id}>
                            <TableCell className={classes.truncated}>
                                <TextWithTooltip text={log.operation} />
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                <TextWithTooltip text={log.entityKind} />
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                <TextWithTooltip text={log.whatChanged} />
                            </TableCell>
                            <TableCell
                                className={clsx(classes.truncated, {
                                    [classes.entitySpecified]: log.entitySpecified,
                                })}>
                                {log.grantOption ? (
                                    <Tooltip title={log.grantOption}>
                                        <span>
                                            <FontAwesomeIcon
                                                size="sm"
                                                className={classes.adminOptionIcon}
                                                icon={faUserShield}
                                            />
                                            {log.entity}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <TextWithTooltip text={log.entity} />
                                )}
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                <TextWithTooltip text={log.roleAffected} />
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                <UserAndRolesTableCell user={log.user} roles={log.roles} />
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                {parseISO(log.atTime.toString()).toLocaleString()}
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                {log.queryId && (
                                    <Grid container>
                                        <Tooltip title={log.queryId}>
                                            <Link
                                                to={`/query/${log.queryId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={`${classes.link} ${classes.truncated}`}>
                                                {log.queryId}
                                            </Link>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    {hasMoreRecords && (
                        <TableRow>
                            <TableCell colSpan={7}>
                                {isFetchingPage ? (
                                    <Spinner size={50} position="relative" />
                                ) : (
                                    <LoadMoreIntersectionButton onLoadMore={fetchMore} />
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
