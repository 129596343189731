/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { paletteSwitch } from '../../../../themes/palette';
import { Spinner } from '../../../../components/spinner/Spinner';
import { LoadMoreIntersectionButton } from '../../../dataproduct/pagination/LoadMoreIntersectionButton';
import { parseISO } from 'date-fns';
import { AccessLogEntry } from './useAccessLogs';
import { TextWithTooltip } from '../TextWithTooltip';
import { UserAndRolesTableCell } from '../UserAndRolesTableCell';
import Grid from '@mui/material/Grid';
import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTableStyles } from '../../../../components/table/useTableStyles';
import { TableHeaderCell } from '../../../../components/table/TableHeaderCell';

interface ChangesTableProps {
    logs: AccessLogEntry[];
    hasMoreRecords: boolean;
    isFetchingPage: boolean;
    fetchMore: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
    container: {
        maxHeight: 'calc(100vh - 22.5rem)',
    },
    truncated: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tableHeadCell: {
        fontWeight: 600,
        letterSpacing: '0.5px',
        fontFamily: theme.typography.fontFamily,
    },
    link: {
        color: paletteSwitch(theme).purple,
        letterSpacing: '0.5px',
    },
}));

export const AccessLogTable: FunctionComponent<ChangesTableProps> = ({
    logs,
    hasMoreRecords,
    isFetchingPage,
    fetchMore,
}) => {
    const classes = useStyles();
    const tableClasses = useTableStyles();
    return (
        <TableContainer className={clsx(tableClasses.table, classes.container)}>
            <Table stickyHeader style={{ tableLayout: 'fixed' }}>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell widthInPercentage={10}>Object type</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={25}>Object affected</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={10}>Access</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={10}>Operation</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={10}>User</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={15}>Time</TableHeaderCell>
                        <TableHeaderCell widthInPercentage={20}>Query ID</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs.map((log) => (
                        <TableRow hover key={log.id}>
                            <TableCell className={classes.truncated}>
                                <TextWithTooltip text={log.entityCategory} />
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                <TextWithTooltip text={log.entity} />
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                <TextWithTooltip text={log.accessResult} />
                            </TableCell>
                            <TableCell className={classes.truncated}>{log.action}</TableCell>
                            <TableCell className={classes.truncated}>
                                <UserAndRolesTableCell user={log.user} roles={log.roles} />
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                {parseISO(log.atTime.toString()).toLocaleString()}
                            </TableCell>
                            <TableCell className={classes.truncated}>
                                {log.queryId && (
                                    <Grid container>
                                        <Tooltip title={log.queryId}>
                                            <Link
                                                to={`/query/${log.queryId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={`${classes.link} ${classes.truncated}`}>
                                                {log.queryId}
                                            </Link>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    {hasMoreRecords && (
                        <TableRow>
                            <TableCell colSpan={7}>
                                {isFetchingPage ? (
                                    <Spinner size={50} position="relative" />
                                ) : (
                                    <LoadMoreIntersectionButton onLoadMore={fetchMore} />
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
