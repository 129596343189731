/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo } from 'react';
import { AppRouter } from './AppRouter';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'moment-duration-format';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { Theming } from './Theming';
import { UIThemeContextProvider } from './UIThemeContextProvider';

export type ThemeMode = 'light' | 'dark';

export const App: React.FunctionComponent = () => {
    const [themeMode, setThemeMode] = React.useState<ThemeMode>(
        (localStorage.getItem('mode') as ThemeMode) || 'light'
    );

    const toggleTheme = useMemo(
        () => ({
            toggleUITheme: () => {
                setThemeMode((prevMode: ThemeMode) => {
                    const nextMode = prevMode === 'light' ? 'dark' : 'light';
                    localStorage.setItem('mode', nextMode);
                    return nextMode;
                });
            },
        }),
        []
    );

    return (
        <UIThemeContextProvider toggleUITheme={toggleTheme}>
            <Theming themeMode={themeMode}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Provider store={store}>
                        <AppRouter />
                    </Provider>
                </LocalizationProvider>
            </Theming>
        </UIThemeContextProvider>
    );
};
