/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { logoutRoute } from './login/routing/LoginRouting';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { createUseStyles } from 'react-jss';
import axios from 'axios';
import { logout } from '../api/loginApi';
import { useIdleTimer } from 'react-idle-timer';
import clsx from 'clsx';
import { paletteSwitch } from '../themes/palette';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    progressButton: {
        width: '7.5rem',
        position: 'relative',
        '&:before': {
            animation: '$progress 15s linear forwards',
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '4px',
        },
    },
    progressButtonLight: {
        '&:before': {
            backgroundColor: paletteSwitch(theme).black08,
        },
    },
    progressButtonDark: {
        '&:before': {
            backgroundColor: paletteSwitch(theme).black20,
        },
    },
    '@keyframes progress': {
        '0%': {
            width: '100%',
        },
        '100%': {
            width: '0%',
        },
    },
    dialogMessage: {
        fontFamily: 'montserrat, sans-serif',
        fontSize: '1.25rem',
        fontWeight: 600,
    },
}));

interface InactivityAndUnauthorizedDialogProps {
    userInactivityTimeoutInMs: number;
}

export const InactivatedOrUnauthorizedDialog: React.FunctionComponent<
    InactivityAndUnauthorizedDialogProps
> = ({ userInactivityTimeoutInMs }) => {
    const classes = useStyles();
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [authExpired, setAuthExpired] = useState(false);
    const [inactivityExceeded, setInactivityExceeded] = useState(false);
    const [remaining, setRemaining] = useState<number>(15);

    useEffect(() => {
        if (authExpired) {
            return;
        }
        // For Unauthorized requests or session timeout requests
        const interceptorId = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    setAuthExpired(true);
                    setOpenDialog(true);
                }
                return Promise.reject(error);
            }
        );
        return () => axios.interceptors.response.eject(interceptorId);
    }, [authExpired]);

    const handleDismiss = () => {
        setOpenDialog(false);
        activate();
    };

    const handleLogout = () => {
        if (authExpired) {
            history.push(logoutRoute);
        } else if (inactivityExceeded) {
            logout().finally(() => history.push(logoutRoute));
        }
    };

    const onActive = () => setInactivityExceeded(false);

    const onPrompt = () => {
        setInactivityExceeded(true);
        setOpenDialog(true);
    };

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle: handleLogout,
        onActive,
        onPrompt,
        timeout: userInactivityTimeoutInMs,
        promptBeforeIdle: 15_000,
        throttle: 1_000,
    });

    useEffect(() => {
        if (!inactivityExceeded) {
            return;
        }

        setRemaining(15);
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => clearInterval(interval);
    }, [inactivityExceeded]);

    if (authExpired) {
        return (
            <Dialog open={openDialog} disableEscapeKeyDown>
                <DialogTitle>
                    <div className={classes.dialogMessage}>Expired session</div>
                </DialogTitle>
                <DialogContent style={{ width: '30rem' }}>
                    <DialogContentText>
                        Your session has expired. Log back in to start a new session.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: '0 1rem 1rem 0' }}>
                    <Button onClick={handleDismiss} color="primary" variant="outlined">
                        Dismiss
                    </Button>
                    <Button
                        onClick={handleLogout}
                        color="primary"
                        variant="contained"
                        className={clsx({
                            [classes.progressButtonDark]: inactivityExceeded,
                            [classes.progressButton]: inactivityExceeded,
                        })}>
                        {inactivityExceeded ? `Log out (${remaining})` : 'Log out'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else if (inactivityExceeded) {
        return (
            <Dialog open={openDialog} disableEscapeKeyDown>
                <DialogTitle>
                    <div className={classes.dialogMessage}>Your session is about to expire</div>
                </DialogTitle>
                <DialogContent style={{ width: '30rem' }}>
                    <DialogContentText>Do you want to stay signed in?</DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: '0 1rem 1rem 0' }}>
                    <Button
                        onClick={handleLogout}
                        color="primary"
                        variant="outlined"
                        className={clsx(classes.progressButton, classes.progressButtonLight)}>
                        {`Log out (${remaining})`}
                    </Button>
                    <Button onClick={handleDismiss} color="primary" variant="contained">
                        Yes, keep me signed in
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    return null;
};
