/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDialogOpenState } from '../../../components/dialog/useDialogOpenState';
import { SimpleDialog } from '../../../components/dialog/SimpleDialog';
import {
    getUserDataProductPermissions$,
    listDataProducts$,
} from '../../../api/dataProduct/dataProductApi';
import TextField from '@mui/material/TextField';
import { createUseStyles } from 'react-jss';
import Link from '@mui/material/Link';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { ChooserErrorIcon } from '../../biac/grants/ChooserErrorIcon';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Theming } from '../../../app/Theming';
import Grid from '@mui/material/Grid';
import {
    DataProductWizardInputEvent,
    useDataProductCreateRedirection,
    useDataProductUpdateRedirection,
} from '../routing/dataProductRoutingUtils';
import {
    UserGlobalPermissionContextProvider,
    useUserGlobalPermissionContext,
} from '../permission/UserGlobalPermissionContext';
import { Spinner } from '../../../components/spinner/Spinner';
import { useThemeMode } from '../../../app/UIThemeContextProvider';

interface AddToDataProductFromQueryLinkProps {
    query?: string;
    className?: string;
}

export const AddToDataProductFromQueryLink = ({
    query,
    className,
}: AddToDataProductFromQueryLinkProps) => {
    const { isOpen, close, open } = useDialogOpenState();
    const themeMode = useThemeMode();
    return (
        <Theming themeMode={themeMode}>
            <>
                <Link color="secondary" underline="hover" className={className} onClick={open}>
                    Add to data product
                </Link>
                {isOpen && <AddToDataProductDialog isOpen={isOpen} close={close} query={query} />}
            </>
        </Theming>
    );
};

interface AddToDataProductDialogProps {
    isOpen: boolean;
    close: () => void;
    query?: string;
}

enum AddToProductType {
    CREATE = 'CREATE',
    ADD_TO_EXISTING = 'ADD_TO_EXISTING',
}

const AddToDataProductDialog = ({ isOpen, close, query }: AddToDataProductDialogProps) => {
    const [addToProductType, setAddToProductType] = useState<AddToProductType>(
        AddToProductType.CREATE
    );
    const [dataProductId, setDataProductId] = useState<string>();

    const errorState = useState<string | null>(null);
    const [error, setError] = errorState;

    const [canUpdateDataProduct, setCanUpdateDataProduct] = useState<boolean | 'loading'>(false);

    useEffect(() => {
        if (dataProductId) {
            setCanUpdateDataProduct('loading');
            const subscription = getUserDataProductPermissions$(dataProductId).subscribe(
                (data) => setCanUpdateDataProduct(data.canUpdate),
                ({ message }) => {
                    setError(message);
                    setCanUpdateDataProduct(false);
                }
            );
            return () => subscription.unsubscribe();
        }
    }, [dataProductId]);

    useEffect(() => {
        if (addToProductType === AddToProductType.CREATE) {
            setDataProductId(undefined);
        }
    }, [addToProductType]);

    const redirectionPayload: DataProductWizardInputEvent = useMemo(() => {
        return {
            type: 'addDataset',
            name: 'created_from_query_editor',
            query: query ?? '',
            navigateToNewDataset: addToProductType === AddToProductType.ADD_TO_EXISTING,
        };
    }, [addToProductType, query]);
    const createRedirection = useDataProductCreateRedirection('', redirectionPayload);
    const updateRedirection = useDataProductUpdateRedirection(
        dataProductId ?? '',
        redirectionPayload
    );

    const handleConfirm = useCallback(async () => {
        if (addToProductType === AddToProductType.CREATE) {
            await createRedirection();
        } else {
            await updateRedirection();
        }
    }, [addToProductType, createRedirection, updateRedirection]);

    return (
        <SimpleDialog<AddToDataProductContentProps>
            isOpen={isOpen}
            close={close}
            title="Add to data product"
            confirmButtonLabel="Continue"
            onConfirm={handleConfirm}
            isConfirmationButtonDisabled={
                (addToProductType === AddToProductType.ADD_TO_EXISTING &&
                    (!dataProductId || canUpdateDataProduct !== true)) ||
                !!error
            }
            fullWidth={true}
            contentProps={{
                addToProductType,
                setAddToProductType,
                dataProductId,
                setDataProductId,
                canUpdateDataProduct,
                errorState,
            }}
            Content={AddToDataProductContentWrapper}
        />
    );
};

interface AddToDataProductContentProps {
    addToProductType: AddToProductType;
    setAddToProductType: (newType: AddToProductType) => void;
    setDataProductId: (newType: string | undefined) => void;
    dataProductId?: string;
    canUpdateDataProduct: boolean | 'loading';
    errorState: [string | null, React.Dispatch<React.SetStateAction<string | null>>];
}

interface DataProductInfo {
    label: string;
    id: string;
}

const useAddToDataProductContentClasses = createUseStyles({
    dataProductDropdown: {
        marginLeft: '1.8rem',
    },
});

const AddToDataProductContentWrapper = (props: AddToDataProductContentProps) => {
    return (
        <UserGlobalPermissionContextProvider>
            <AddToDataProductContent {...props} />
        </UserGlobalPermissionContextProvider>
    );
};

const AddToDataProductContent = ({
    addToProductType,
    setAddToProductType,
    dataProductId,
    setDataProductId,
    canUpdateDataProduct,
    errorState: [error, setError],
}: AddToDataProductContentProps) => {
    const internalClasses = useAddToDataProductContentClasses();
    const [applicableDataProducts, setApplicableDataProducts] = useState<DataProductInfo[]>([]);

    const [busy, setBusy] = useState<boolean>(false);

    const canCreateDataProducts = useUserGlobalPermissionContext().canCreateDataProducts;
    useEffect(() => {
        if (!canCreateDataProducts) {
            setAddToProductType(AddToProductType.ADD_TO_EXISTING);
        }
    }, [canCreateDataProducts]);

    useEffect(() => {
        if (canUpdateDataProduct === 'loading') {
            setBusy(true);
        } else {
            setBusy(false);
        }
    }, [canUpdateDataProduct]);

    const dataProductsSelectorLabel = useMemo(() => {
        if (!busy && applicableDataProducts.length === 0) {
            return 'No data products available';
        } else {
            return 'Data products';
        }
    }, [busy, applicableDataProducts]);

    useEffect(() => {
        setBusy(true);
        setError(null);
        if (addToProductType === AddToProductType.ADD_TO_EXISTING) {
            const subscription = listDataProducts$({}).subscribe({
                next: (products) => {
                    setApplicableDataProducts(
                        products.map((it) => ({
                            label: it.name,
                            id: it.id,
                        }))
                    );
                    setBusy(false);
                },
                error: (error) => {
                    setBusy(false);
                    setError(error);
                },
            });

            return () => {
                subscription.unsubscribe();
            };
        } else {
            setApplicableDataProducts([]);
            setBusy(false);
        }
    }, [addToProductType]);

    const renderPopupIcon = useCallback(() => {
        if (busy) {
            return (
                <Spinner
                    position="no-wrapper"
                    size={20}
                    delay={500}
                    placeholder={<ArrowDropDownOutlinedIcon />}
                />
            );
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title="Loading data products failed" />
                </div>
            );
        } else {
            return <ArrowDropDownOutlinedIcon />;
        }
    }, [busy, error]);

    const onChange = useCallback(
        (event: React.SyntheticEvent, value: DataProductInfo | null) => {
            setDataProductId(value?.id);
        },
        [setDataProductId]
    );

    const selectedValue = useMemo(() => {
        return applicableDataProducts.find((it) => it.id === dataProductId);
    }, [dataProductId, applicableDataProducts]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <RadioGroup
                        value={addToProductType}
                        onChange={(e) => setAddToProductType(e.target.value as AddToProductType)}>
                        <FormControlLabel
                            disabled={!canCreateDataProducts}
                            value={AddToProductType.CREATE}
                            control={<Radio />}
                            label="Create a new data product"
                        />
                        <FormControlLabel
                            value={AddToProductType.ADD_TO_EXISTING}
                            control={<Radio />}
                            label="Add to an existing data product"
                        />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                    {addToProductType === AddToProductType.ADD_TO_EXISTING && (
                        <Autocomplete
                            className={internalClasses.dataProductDropdown}
                            value={selectedValue ?? null}
                            clearText="Clear selected data products"
                            options={applicableDataProducts}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disabled={!applicableDataProducts.length}
                            onChange={onChange}
                            popupIcon={renderPopupIcon()}
                            renderInput={(params) => {
                                const dataProductCantBeChanged =
                                    !!dataProductId && canUpdateDataProduct === false;
                                return (
                                    <TextField
                                        {...params}
                                        label={dataProductsSelectorLabel}
                                        error={dataProductCantBeChanged}
                                        helperText={
                                            dataProductCantBeChanged
                                                ? 'You do not have permission to edit this data product'
                                                : undefined
                                        }
                                    />
                                );
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
