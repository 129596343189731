/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { prepareLineChartData } from '../../utils/lineChart';
import { defaultChartPalette } from '../../themes/palette';
import { ChartWrapper } from '../../components/chart/ChartWrapper';
import Box from '@mui/material/Box';
import { TimeLineChart } from '../../components/chart/TimeLineChart';
import { CumulativeCpuUsageResponse } from '../../api/usageApi';
import { formatDuration } from '../../utils/formatDuration';
import { createUseStyles } from 'react-jss';
import Card from '@mui/material/Card';

const useStyles = createUseStyles({
    container: {
        boxShadow: 'none',
        padding: '16px 10px 0px 6px',
    },
    header: {
        fontFamily: 'montserrat, sans-serif',
        fontSize: '1.125rem',
        fontWeight: 600,
        padding: '0.5rem 0 1.75rem 0',
    },
    chartHeader: {
        fontWeight: 600,
        marginLeft: '12px',
    },
    chart: {
        margin: '28px 6px 16px 0',
    },
});

interface CumulativeUsageChartProps {
    cpuUsage: CumulativeCpuUsageResponse | 'error';
    height: number;
}

export const CumulativeUsageChart: React.FunctionComponent<CumulativeUsageChartProps> = ({
    cpuUsage,
    height,
}) => {
    const classes = useStyles();

    const data = prepareLineChartData(
        'Cumulative CPU usage',
        cpuUsage,
        'intervalEnd',
        ({ cpuUsage }) => cpuUsage * 1000, // converts seconds to millis
        { fill: 'origin', color: defaultChartPalette[4] }
    );

    const valueFormatter = (value: number): string => formatDuration(value);

    return (
        <>
            <Box className={classes.header}>CPU usage summary</Box>
            <Card>
                <ChartWrapper
                    data={data}
                    title={
                        <Box mr="auto" className={classes.chartHeader}>
                            CPU usage
                        </Box>
                    }
                    height={height}
                    classes={{ container: classes.container, chart: classes.chart }}
                    legend={{
                        type: 'dataset',
                        variant: 'inline',
                        valueFormatter: () => null,
                    }}>
                    {(chartData): React.ReactNode => (
                        <TimeLineChart
                            data={chartData}
                            height={height}
                            tooltipValueFormatter={valueFormatter}
                            yLabelFormatter={'duration'}
                            yTicksLimit={6}
                            xTicksLimit={5}
                            xTicksMaxRotation={0}
                            tooltipDateFormat={{
                                '12h': 'MMM d, h:mm aaa',
                                '24h': 'MMM d, H:mm',
                            }}
                            labelWidth={70}
                        />
                    )}
                </ChartWrapper>
            </Card>
        </>
    );
};
