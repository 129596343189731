/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Select } from '../../components/select/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import Card from '@mui/material/Card';
import { addMonths, addYears, format, isAfter, isBefore, isValid } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { localeDateFormat } from '../../components/date/dateFormats';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import {
    setFilters,
    setOption,
    UsageMetricsCriteria,
    UsageMetricsPredefinedFilter,
} from './usageMetricsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { minValidDate } from '../../api/usageApi';
import TextField from '@mui/material/TextField';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        padding: '0.25rem 1.25rem 0.5rem 1.25rem',
    },
    card: {
        marginBottom: '16px',
    },
    filtersHeader: {
        color: paletteSwitch(theme).purple,
        fontWeight: 600,
        paddingTop: '18px',
        width: '95px',
    },
    label: {
        color: paletteSwitch(theme).black54,
    },
    datePickerGrid: {
        marginTop: '0.25rem',
    },
    validationBox: {
        display: 'none',
    },
    button: {
        marginLeft: 'auto',
        marginTop: '6px',
        marginBottom: '3px',
    },
    spacing: {
        paddingLeft: '8px',
    },
    fieldProps: {},
}));

export const UsageMetricsFilters: React.FunctionComponent = () => {
    const classes = useStyles();
    const currentDate = new Date();
    const singleRow = useMediaQuery(useTheme().breakpoints.up('md'));

    const filters = useSelector(({ usageMetrics }: RootState) => usageMetrics.filters);
    const [localFilters, setLocalFilters] = useState<UsageMetricsCriteria>(filters);
    const dispatch = useDispatch();

    const options: Array<{
        label: React.ReactNode;
        value: UsageMetricsPredefinedFilter;
    }> = [
        {
            label: (
                <Box display="flex" width={'100%'}>
                    <Box>Today</Box>
                    <Box className={classes.label} ml={'auto'}>
                        {format(currentDate, 'dd MMMM yyyy')}
                    </Box>
                </Box>
            ),
            value: 'this_day',
        },
        {
            label: (
                <Box display="flex" width={'100%'}>
                    <Box>Current month</Box>
                    <Box className={classes.label} ml={'auto'}>
                        {format(currentDate, 'MMMM')}
                    </Box>
                </Box>
            ),
            value: 'this_month',
        },
        {
            label: (
                <Box display="flex" width={'100%'}>
                    <Box>Last month</Box>
                    <Box className={classes.label} ml={'auto'}>
                        {format(addMonths(currentDate, -1), 'MMMM')}
                    </Box>
                </Box>
            ),
            value: 'last_month',
        },
        {
            label: (
                <Box display="flex" width={'100%'}>
                    <Box>Current year</Box>
                    <Box className={classes.label} ml={'auto'}>
                        {format(currentDate, 'yyyy')}
                    </Box>
                </Box>
            ),
            value: 'this_year',
        },
        {
            label: (
                <Box display="flex" width={'100%'}>
                    <Box>Last year</Box>
                    <Box className={classes.label} ml={'auto'}>
                        {format(addYears(currentDate, -1), 'yyyy')}
                    </Box>
                </Box>
            ),
            value: 'last_year',
        },
        {
            label: (
                <Box display="flex" width={'100%'}>
                    <Box>All available dates</Box>
                </Box>
            ),
            value: 'all',
        },
        { label: 'Custom range', value: 'custom' },
    ];

    const dateInvalid = (inputDate: Date | null): boolean => {
        return (
            inputDate !== null &&
            (!isValid(inputDate) ||
                isBefore(inputDate, minValidDate) ||
                isAfter(inputDate, new Date(2070, 0, 1)))
        );
    };

    const onOptionUpdate = (option: UsageMetricsPredefinedFilter) => {
        setLocalFilters((prevState) => ({
            ...prevState,
            option,
        }));

        if (option !== 'custom') {
            dispatch(setOption(option));
        }
    };

    const applyFilters = () => {
        dispatch(setFilters(localFilters));
    };

    return (
        <Box mb={1}>
            <Card className={classes.card}>
                <Box p={1} className={classes.root}>
                    <Box display={'flex'}>
                        <Box
                            display={singleRow ? 'block' : 'none'}
                            className={classes.filtersHeader}>
                            Date filter
                        </Box>
                        <Grid container alignItems="center">
                            <Grid
                                item
                                xs={12}
                                md={4}
                                lg={4}
                                xl={3}
                                className={clsx({ [classes.spacing]: singleRow })}>
                                <FormControl
                                    key="status"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth>
                                    <Select
                                        inputProps={{ classes: { root: classes.fieldProps } }}
                                        value={localFilters.option}
                                        onChange={(e): void => {
                                            onOptionUpdate(
                                                e.target.value as UsageMetricsPredefinedFilter
                                            );
                                        }}>
                                        {options.map(({ label, value }) => (
                                            <MenuItem key={value} value={value}>
                                                {label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {localFilters.option === 'custom' && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={3}
                                        xl={3}
                                        className={clsx(
                                            { [classes.spacing]: singleRow },
                                            classes.datePickerGrid
                                        )}>
                                        <DatePicker<Date | null>
                                            label={'Pick start date'}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            inputFormat={localeDateFormat}
                                            value={localFilters.startDay}
                                            onChange={(startDay) =>
                                                setLocalFilters((prevState) => ({
                                                    ...prevState,
                                                    startDay,
                                                }))
                                            }
                                            minDate={new Date(2010, 0, 1)}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        lg={3}
                                        xl={3}
                                        className={clsx(
                                            { [classes.spacing]: singleRow },
                                            classes.datePickerGrid
                                        )}>
                                        <DatePicker<Date | null>
                                            label={'Pick end date'}
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            inputFormat={localeDateFormat}
                                            value={localFilters.endDay}
                                            onChange={(endDay) =>
                                                setLocalFilters((prevState) => ({
                                                    ...prevState,
                                                    endDay,
                                                }))
                                            }
                                            minDate={new Date(2010, 0, 1)}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={2}
                                        xl={3}
                                        style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                        <Box display={'flex'}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                disabled={
                                                    localFilters.startDay === null ||
                                                    localFilters.endDay === null ||
                                                    dateInvalid(localFilters.startDay) ||
                                                    dateInvalid(localFilters.endDay)
                                                }
                                                onClick={applyFilters}>
                                                Apply filter
                                            </Button>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};
