/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Reply from '@mui/icons-material/Reply';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { paletteSwitch } from '../../../themes/palette';
import {
    CommentData,
    createDataProductComment$,
    updateDataProductComment$,
} from '../../../api/dataProduct/comment/commentApi';
import { DeleteCommentModal } from './DeleteCommentModal';
import { CommentContentEditor } from './CommentContentEditor';
import { useCallback$ } from '../util/useCallback';
import { useDataProductPermissionContext } from '../permission/DataProductPermissionContext';
import { useIsLoggedInUserCommentAuthor } from './useIsLoggedInUserCommentAuthor';
import { ThumbVote } from './ThumbVote';
import { formatDatetime } from '../../../utils/formatDateInterval';
import { MarkDown } from '../../../components/markDown/MarkDown';
import { Theme } from '@mui/material/styles';

const maxReplyDepth = 5;

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '1rem',
        borderBottom: `1px solid ${paletteSwitch(theme).nebulaNavy50}`,
        marginBottom: '1.1875rem',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    commentInfo: {
        fontWeight: 400,
        color: paletteSwitch(theme).black54,
        fontSize: '0.75rem',
    },
    commentEditedIndicator: {
        fontStyle: 'italic',
    },
    commentContent: {
        marginTop: '0.875rem',
    },
    thumbVote: {
        justifyContent: 'end',
    },
    replyContainer: {
        marginLeft: '1rem',
        marginTop: '1.5rem',
        paddingLeft: '1rem',
        borderLeft: `2px solid ${paletteSwitch(theme).fusionFuchsia_12}`,
    },
    toggleReplies: {
        color: paletteSwitch(theme).purple,
        fontSize: '14px',
        cursor: 'pointer',
    },
    commentManipulator: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    commentManipulatorRightPanel: {
        display: 'flex',
        justifyContent: 'end',
        flexGrow: 1,
    },
}));
enum ContentMode {
    DISPLAY = 'display',
    EDIT = 'edit',
}
type CommentProps = Omit<CommentData, 'id'> & {
    commentId: string;
    commentNestingLevel?: number;
};
export const Comment: FunctionComponent<CommentProps> = ({
    commentId,
    dataProductId,
    createdBy,
    createdAt,
    updatedAt,
    commentText,
    upvotedByCurrentUser,
    upvotes,
    replies,
    commentNestingLevel = 0,
    deleted,
}: CommentProps) => {
    const styles = useStyles();
    const [contentMode, setContentMode] = useState(ContentMode.DISPLAY);
    const [editedContent, setEditedContent] = useState('');
    const handleEditClick = useCallback(() => {
        setContentMode(ContentMode.EDIT);
        setEditedContent(commentText);
    }, [commentText]);

    const [isReplyModeOn, setIsReplyModeOn] = useState(false);
    const turnReplyModeOn = useCallback(() => setIsReplyModeOn(true), []);
    const turnReplyModeOff = useCallback(() => setIsReplyModeOn(false), []);

    const [replyCommentText, setReplyCommentText] = useState('');

    const [areCommentRepliesVisible, setAreCommentRepliesVisible] = useState(false);
    const showCommentReplies = useCallback(() => setAreCommentRepliesVisible(true), []);
    const hideCommentReplies = useCallback(() => setAreCommentRepliesVisible(false), []);
    const handleEditConfirm = useCallback$(
        () =>
            updateDataProductComment$(dataProductId, commentId, editedContent).subscribe(() =>
                setContentMode(ContentMode.DISPLAY)
            ),
        [dataProductId, commentId, editedContent]
    );
    const handleReply = useCallback$(
        () =>
            createDataProductComment$(dataProductId, replyCommentText, commentId).subscribe(() => {
                setReplyCommentText('');
                turnReplyModeOff();
                setAreCommentRepliesVisible(true);
            }),
        [dataProductId, commentId, replyCommentText]
    );
    const handleEditCancel = useCallback(() => {
        setContentMode(ContentMode.DISPLAY);
    }, []);

    const isAuthorOfComment = useIsLoggedInUserCommentAuthor(createdBy);
    const { canUpdate: canUpdateDataProduct } = useDataProductPermissionContext();
    const canManageComment = isAuthorOfComment || canUpdateDataProduct;

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.commentInfo}>
                    <div>{createdBy}</div>
                    <div>
                        <span>{formatDatetime(createdAt)}</span>
                        {updatedAt !== createdAt && (
                            <span className={styles.commentEditedIndicator}> (edited)</span>
                        )}
                    </div>
                </div>
                {!deleted && (
                    <div>
                        <DeleteCommentModal
                            dataProductId={dataProductId}
                            commentId={commentId}
                            createdAt={createdAt}
                            createdBy={createdBy}
                            opener={(openModal) => (
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={openModal}
                                    disabled={!canManageComment}>
                                    <DeleteOutline />
                                </IconButton>
                            )}
                        />
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={handleEditClick}
                            disabled={!isAuthorOfComment}>
                            <Edit />
                        </IconButton>
                    </div>
                )}
            </div>
            <div className={styles.commentContent}>
                {contentMode === ContentMode.DISPLAY ? (
                    <MarkDown text={commentText} />
                ) : (
                    <CommentContentEditor
                        content={editedContent}
                        onContentChange={setEditedContent}
                        onConfirm={handleEditConfirm}
                        onCancel={handleEditCancel}
                    />
                )}
            </div>
            {contentMode === ContentMode.DISPLAY && (
                <div className={styles.commentManipulator}>
                    {!!replies?.length && (
                        <div
                            className={styles.toggleReplies}
                            onClick={
                                areCommentRepliesVisible ? hideCommentReplies : showCommentReplies
                            }>
                            {areCommentRepliesVisible ? 'Hide replies' : 'Show replies'}
                        </div>
                    )}
                    <div className={styles.commentManipulatorRightPanel}>
                        {!deleted && (
                            <ThumbVote
                                dataProductId={dataProductId}
                                commentId={commentId}
                                isVoted={upvotedByCurrentUser}
                                voteCount={upvotes}
                                className={styles.thumbVote}
                            />
                        )}
                        {commentNestingLevel < maxReplyDepth && (
                            <IconButton color="primary" size="small" onClick={turnReplyModeOn}>
                                <Reply />
                            </IconButton>
                        )}
                    </div>
                </div>
            )}
            {isReplyModeOn && (
                <div className={styles.replyContainer}>
                    <CommentContentEditor
                        content={replyCommentText}
                        onContentChange={setReplyCommentText}
                        onConfirm={handleReply}
                        onCancel={turnReplyModeOff}
                    />
                </div>
            )}
            {areCommentRepliesVisible && (
                <div className={styles.replyContainer}>
                    {replies?.map(({ id: replyId, ...properties }) => (
                        <Comment
                            key={replyId}
                            commentNestingLevel={commentNestingLevel + 1}
                            commentId={replyId}
                            {...properties}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
