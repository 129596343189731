/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';

import { EnterpriseLogo } from '../../components/logos/EnterpriseLogo';
import { useStyles } from '../../themes/styles';
import { createUseStyles } from 'react-jss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logoutBackground from '../../images/enterprise-signout.jpg';

const useLocalStyles = createUseStyles({
    root: {
        backgroundImage: `url(${logoutBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonContainer: {
        marginTop: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export const LogoutPage: React.FunctionComponent = () => {
    const localClasses = useLocalStyles();
    const classes = useStyles();

    return (
        <Box className={localClasses.root}>
            <Grid container spacing={3} className={localClasses.content}>
                <Grid item xs={12} className={localClasses.logoContainer}>
                    <div>
                        <EnterpriseLogo />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="sm">
                        <Typography variant="h4" component="h4" className={classes.header}>
                            {'You are now logged out.'}
                        </Typography>
                    </Container>
                </Grid>
                <Grid item xs={12} className={localClasses.buttonContainer}>
                    <Button
                        id="sign-back-in-button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            location.href = '/ui/insights/login'; // Browser redirect should happen for oauth instead of react-routing
                        }}>
                        Sign back in
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
