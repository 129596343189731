/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useContext, useMemo, useState } from 'react';
import { addDays, isBefore, parseISO, startOfDay } from 'date-fns';
import { createUseStyles } from 'react-jss';
import { useSavedWorksheets } from '../../../api/savedQueriesApi';
import { useTabStyles } from '../../../themes/useTabStyles';
import { PageContent } from '../../../layout/PageContent';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { DefaultTab } from '../../../components/tab/DefaultTab';
import { SavedWorksheetsList } from './SavedWorksheetsList';
import { TabBadge } from '../../../components/tab/TabBadge';
import { getListResponseCount } from '../../../utils/getListResponseCount';
import { ErrorScreen } from '../../../components/error/ErrorScreen';
import { ConfigContext } from '../../../app/ConfigContextProvider';
import { useRoleHeader } from '../../biac/CurrentRoleContext';
import { isWorksheetOwner } from './WorksheetUtils';

type TabType = 'recent-queries' | 'my-queries' | 'shared-with-me';

const useLocalStyles = createUseStyles({
    root: {
        height: '100%',
    },
});

const updatedInLastSevenDays = (lastUpdateTime: string): boolean => {
    const lastUpdate = parseISO(lastUpdateTime);
    const weekAgo = startOfDay(addDays(new Date(), -7));
    return isBefore(weekAgo, lastUpdate);
};

export const SavedQueriesPage: React.FunctionComponent = () => {
    const classes = useTabStyles();
    const localClasses = useLocalStyles();
    const [tab, setTab] = useState<TabType>('recent-queries');
    const persistenceEnabled = useContext(ConfigContext)?.persistenceEnabled;
    const roleHeader = useRoleHeader();
    const {
        isFetching: savedWorksheetsLoading,
        isError,
        errorMessage,
        data: allWorksheets,
        reload: reloadWorksheets,
    } = useSavedWorksheets(roleHeader);
    const sharingEnabled = roleHeader !== null;

    const recentWorksheets = useMemo(
        () => allWorksheets?.filter(({ lastUpdateTime }) => updatedInLastSevenDays(lastUpdateTime)),
        [allWorksheets]
    );
    const myWorksheets = useMemo(
        () => allWorksheets?.filter(isWorksheetOwner),
        [allWorksheets, isWorksheetOwner]
    );

    const sharedWithMe = useMemo(
        () => allWorksheets?.filter((ws) => !isWorksheetOwner(ws)),
        [allWorksheets, isWorksheetOwner]
    );

    if (!persistenceEnabled) {
        return (
            <ErrorScreen
                errorCode={404}
                errorMsg="Page not found"
                errorDescription="The page you're looking for doesn't exist."
            />
        );
    }
    return (
        <PageContent title={'Saved queries'}>
            <Box className={localClasses.root}>
                <Box className={classes.tabs}>
                    <Tabs
                        value={tab}
                        variant="scrollable"
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(_, newValue) => setTab(newValue)}>
                        <DefaultTab
                            value="recent-queries"
                            label={<span className={classes.label}>Recent</span>}
                        />
                        <DefaultTab
                            label={
                                <span className={classes.label}>
                                    Saved Queries{' '}
                                    <TabBadge
                                        value={getListResponseCount(
                                            myWorksheets?.length,
                                            isError,
                                            savedWorksheetsLoading
                                        )}
                                    />
                                </span>
                            }
                            value="my-queries"
                            tab={tab}
                        />
                        {sharingEnabled && (
                            <DefaultTab
                                label={
                                    <span className={classes.label}>
                                        Shared with me{' '}
                                        <TabBadge
                                            value={getListResponseCount(
                                                sharedWithMe?.length,
                                                isError,
                                                savedWorksheetsLoading
                                            )}
                                        />
                                    </span>
                                }
                                value="shared-with-me"
                                tab={tab}
                            />
                        )}
                    </Tabs>
                </Box>
                {tab === 'recent-queries' && (
                    <SavedWorksheetsList
                        worksheets={recentWorksheets}
                        worksheetsError={errorMessage}
                        worksheetsLoading={savedWorksheetsLoading}
                        reloadWorksheets={reloadWorksheets}
                        showSharedWithColumn={sharingEnabled}
                        showShareButton={sharingEnabled}
                    />
                )}
                {tab === 'my-queries' && (
                    <SavedWorksheetsList
                        worksheets={myWorksheets}
                        worksheetsError={errorMessage}
                        worksheetsLoading={savedWorksheetsLoading}
                        reloadWorksheets={reloadWorksheets}
                        showSharedWithColumn={sharingEnabled}
                        showShareButton={sharingEnabled}
                    />
                )}
                {tab === 'shared-with-me' && (
                    <SavedWorksheetsList
                        worksheets={sharedWithMe}
                        worksheetsError={errorMessage}
                        worksheetsLoading={savedWorksheetsLoading}
                        reloadWorksheets={reloadWorksheets}
                        showSharedWithColumn={sharingEnabled}
                        showShareButton={false}
                    />
                )}
            </Box>
        </PageContent>
    );
};
