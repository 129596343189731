/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useEffect, useRef, useState } from 'react';
import { optionalCallbacks, TrinoClient } from '@starburstdata/query-editor';
import { statementSubmissionUrl } from '../ide/QueryEditor';

const useStorageUsagePoller = (interval: number) => {
    const [storageUsage, setStorageUsage] = useState<number>();
    const storageTrinoQueryClient = useRef(new TrinoClient());
    const pollStorageTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();
    const catalogName = useRef<string>();

    const getFirstAcceleratedCatalog = () =>
        new Promise<string>((resolve, reject) => {
            let res: string;
            storageTrinoQueryClient.current.execute(
                "select catalog_name from system.metadata.catalogs where connector_name = 'warp_speed' limit 1",
                optionalCallbacks({
                    onData: (data) => {
                        res = data[0][0] as string;
                    },
                    onFinish: () => {
                        if (res) {
                            resolve(res);
                        } else {
                            reject();
                        }
                    },
                    onError: reject,
                }),
                {
                    limit: 1,
                    source: 'insights-explorer',
                    submissionUrl: statementSubmissionUrl,
                }
            );
        });

    const loadStorageUsage = (catalogName: string) =>
        new Promise<number>((resolve, reject) => {
            storageTrinoQueryClient.current.execute(
                'SELECT ' +
                    'cast(sum(currentusage)as double)/ cast(sum(totalusage) as double) as storage_usage ' +
                    `FROM "jmx"."current"."io.trino.plugin.varada:catalog=${catalogName},name=warmupdemoter.${catalogName},type=varadastatswarmupdemoter"`,
                optionalCallbacks({
                    onData: (data) => {
                        const res = data[0][0] as number;
                        resolve(res);
                    },
                    onError: reject,
                }),
                {
                    limit: 1,
                    source: 'insights-explorer',
                    submissionUrl: statementSubmissionUrl,
                }
            );
        });

    const pollStorageUsage = async () => {
        try {
            if (!catalogName.current) {
                catalogName.current = await getFirstAcceleratedCatalog();
            }
            const usage = await loadStorageUsage(catalogName.current);
            setStorageUsage(usage);
            pollStorageTimeoutHandle.current = setTimeout(pollStorageUsage, interval);
        } catch {}
    };
    const haltStorageUsagePolling = () => {
        if (pollStorageTimeoutHandle.current) {
            clearTimeout(pollStorageTimeoutHandle.current);
        }
    };
    useEffect(() => {
        pollStorageUsage();
        return haltStorageUsagePolling;
    }, []);

    return storageUsage;
};

export default useStorageUsagePoller;
