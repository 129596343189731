/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext } from 'react';
import { TopBar } from './TopBar';
import { SidePanel } from './sidePanel/SidePanel';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { UnauthorizedDialog } from './UnauthorizedDialog';
import { paletteSwitch } from '../themes/palette';
import { ConfigContext } from '../app/ConfigContextProvider';
import { InactivatedOrUnauthorizedDialog } from './InactivatedOrUnauthorizedDialog';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        maxWidth: '100%',
        overflowX: 'auto',
        height: '100vh',
        backgroundColor: paletteSwitch(theme).main,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
}));

interface MainLayoutProps {
    children: React.ReactNode;
}

export const MainLayout: React.FunctionComponent<MainLayoutProps> = ({ children }) => {
    const configContext = useContext(ConfigContext);
    const classes = useStyles();
    const [openNav, setOpenNav] = React.useState(true);
    const toggleSidePanel = () => {
        setOpenNav((prev) => !prev);
    };

    return (
        <>
            {configContext?.userInactivityTimeoutInMs ? (
                <InactivatedOrUnauthorizedDialog
                    userInactivityTimeoutInMs={configContext.userInactivityTimeoutInMs}
                />
            ) : (
                <UnauthorizedDialog />
            )}
            <div className={classes.root}>
                <TopBar onOpen={toggleSidePanel} drawerEnabled={true} />
                <SidePanel openNav={openNav} setOpenNav={setOpenNav} />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {children}
                </main>
            </div>
        </>
    );
};
