/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import AppBar from '@mui/material/AppBar';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { EnterpriseLogo } from '../components/logos/EnterpriseLogo';
import { HelpMenu } from './HelpMenu';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ProfileMenu } from './ProfileMenu';
import { ThemeSwitchButton } from './ThemeSwitchButton';

const useStyles = createUseStyles((theme: Theme) => ({
    appBar: {
        backgroundColor: theme.palette.primary.dark,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: theme.zIndex.drawer.valueOf() + 1,
        '& .MuiToolbar-regular': {
            minHeight: '56px',
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        transition: 'opacity 0.3s',
        '&:hover': {
            opacity: 0.8,
        },
    },
    separator: {
        width: '2px',
        height: '24px',
        marginLeft: '16px',
        marginRight: '16px',
    },
}));

const Separator: React.FunctionComponent = () => {
    const classes = useStyles();
    return (
        <svg className={classes.separator} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0V24" stroke="white" strokeOpacity="0.870588" />
        </svg>
    );
};

interface TopBarProps {
    drawerEnabled?: boolean;
    onOpen?: () => void;
}

export const TopBar: React.FunctionComponent<TopBarProps> = ({ drawerEnabled, onOpen }) => {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                {drawerEnabled && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onOpen}
                        edge="start"
                        className={classes.menuButton}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                )}
                <EnterpriseLogo width={220} height={32} />
                <ThemeSwitchButton />
                <HelpMenu />
                <Separator />
                <ProfileMenu />
            </Toolbar>
        </AppBar>
    );
};
