/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import { DataProductState, SchemaDataProduct } from '../../api/dataProduct/dataProductApi';
import { paletteSwitch } from '../../themes/palette';
import { shadows } from '../../themes/shadows';
import { MultilineEllipsis } from './components/ellipsis/MultilineEllipsis';
import { PreventDefaultClick } from '../../components/prevent-default/PreventDefaultClick';
import { BookmarkDataProduct } from './components/Bookmark';
import { createDataProductDetailsPath } from './routing/dataProductRoutingUtils';
import { InteractiveRating } from './rating/InteractiveRating';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        '--padding-x': '1.875rem',
    },
    card: {
        boxShadow: shadows[3],
        borderRadius: 0,
        height: '100%',
        border: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
        '&:hover': {
            backgroundColor: paletteSwitch(theme).nebulaNavy15,
            '& $contentEllipsis': {
                backgroundColor: paletteSwitch(theme).nebulaNavy15,
            },
        },
    },
    content: {
        padding: '2rem var(--padding-x) 0 var(--padding-x) ',
    },
    contentItem: {
        width: '100%',
    },
    contentEllipsis: {
        backgroundColor: 'white',
    },
    bookmark: {
        position: 'absolute',
        top: '-8px',
        left: 'var(--padding-x)',
        zIndex: 1,
    },
    statusIndicator: {
        position: 'absolute',
        top: 0,
        right: '0.75rem',
        marginLeft: 'auto',
        float: 'right',
        padding: '6px 10px',
        fontSize: '0.625rem',
        fontWeight: 600,
        height: '28px',
        lineHeight: '16px',
        letterSpacing: '1.5px',
        borderRadius: '0 0 5px 5px',
        color: paletteSwitch(theme).infoDark,
        backgroundColor: paletteSwitch(theme).infoLight,
        border: `1px solid ${paletteSwitch(theme).info}`,
    },
    pendingStatus: {
        backgroundColor: paletteSwitch(theme).hiliteLight,
        color: paletteSwitch(theme).hiliteDark,
        border: `1px solid ${paletteSwitch(theme).hilite}`,
    },
    headerText: {
        fontSize: '1.125rem',
        fontFamily: 'montserrat, sans-serif',
        lineHeight: '28px',
        letterSpacing: '0.15px',
        fontWeight: 600,
        color: paletteSwitch(theme).nebulaNavy,
        marginRight: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    bodyText: {
        fontSize: '1rem',
        overflowWrap: 'break-word',
        lineHeight: '24px',
        letterSpacing: '0.5px',
        flexGrow: 1,
        overflow: 'hidden',
        height: '7.5rem',
        width: '16.688rem',
        marginBottom: '0.5rem',
    },
    ownerSection: {
        marginTop: '0.5rem',
        borderTop: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
    },
    owner: {
        fontSize: '0.625rem',
        fontWeight: 600,
        color: paletteSwitch(theme).black38,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '20px',
        letterSpacing: '1.5px',
    },
    createdBy: {
        color: paletteSwitch(theme).black54,
    },
}));

interface DataProductCardProps {
    dataProduct: SchemaDataProduct;
}

export const DataProductCard: React.FunctionComponent<DataProductCardProps> = ({ dataProduct }) => {
    const classes = useStyles();
    return (
        <Link
            component={RouterLink}
            className={classes.root}
            to={createDataProductDetailsPath(dataProduct.id)}
            underline="none">
            <PreventDefaultClick className={classes.bookmark}>
                <BookmarkDataProduct
                    isBookmarked={dataProduct.userData.isBookmarked}
                    dataProductId={dataProduct.id}
                />
            </PreventDefaultClick>
            <div>
                {dataProduct.status === DataProductState.DRAFT && (
                    <div className={classes.statusIndicator}>{dataProduct.status}</div>
                )}
                {dataProduct.status === DataProductState.PENDING_CHANGES && (
                    <Typography
                        variant="overline"
                        className={clsx(classes.statusIndicator, classes.pendingStatus)}>
                        PENDING CHANGES
                    </Typography>
                )}
            </div>
            <Card className={classes.card} elevation={2}>
                <div className={classes.content}>
                    <div className={classes.headerText}>{dataProduct.name}</div>
                    <div className={clsx(classes.bodyText, classes.contentItem)}>
                        <MultilineEllipsis ellipsisClassName={classes.contentEllipsis}>
                            {dataProduct.summary}
                        </MultilineEllipsis>
                    </div>
                    <PreventDefaultClick>
                        <InteractiveRating
                            entityName={dataProduct.name}
                            entityId={dataProduct.id}
                            userRate={dataProduct.userData.rating || 0}
                            voterCount={dataProduct.ratingsCount}
                            ratingsAverage={dataProduct.ratingsAverage}
                        />
                    </PreventDefaultClick>
                    <div className={clsx(classes.ownerSection, classes.contentItem)}>
                        <Typography variant="overline" display="block" className={classes.owner}>
                            CREATED BY:
                            <br />
                            <span className={classes.createdBy}>{dataProduct.createdBy}</span>
                        </Typography>
                    </div>
                </div>
            </Card>
        </Link>
    );
};
