/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import axios from 'axios';
import { baseUrl } from './api';

interface CreateWorksheetRequest {
    id: string;
    name: string;
    queryText: string;
    tabNumber: number;
}

export const createWorksheet = (
    request: CreateWorksheetRequest,
    controller: AbortController
): Promise<unknown> => {
    return axios
        .post<unknown>(`${baseUrl}/worksheets`, request, {
            signal: controller.signal,
        })
        .then((response) => response.data);
};
