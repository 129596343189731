/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { paletteSwitch } from '../themes/palette';
import { EnterpriseLogo } from '../components/logos/EnterpriseLogo';
import { useStyles } from '../themes/styles';
import background from '../images/nebula.jpg';

const useLocalStyles = createUseStyles((theme: Theme) => ({
    mainGrid: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        overflow: 'auto',
    },
    leftPane: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        width: '56.25%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    rightPane: {
        display: 'flex',
        height: '100%',
        width: '43.75%',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    topPane: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'none',
        padding: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },
    },
    logoContainer: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: theme.zIndex.appBar - 1,
    },
    externalLogo: {
        textAlign: 'center',
        position: 'relative',
        marginBottom: '4rem',
    },
    contentHeader: {
        fontFamily: 'montserrat, sans-serif',
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: '29px',
        color: paletteSwitch(theme).nebulaNavy,
    },
}));

interface BannerLayoutProps {
    welcomeText: string;
    contentHeader?: string;
    contentMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    classes?: {
        content: string;
    };
}

export const BannerLayout: React.FunctionComponent<BannerLayoutProps> = ({
    welcomeText,
    contentHeader,
    contentMaxWidth = 'sm',
    classes: contentClasses,
    children,
}) => {
    const localClasses = useLocalStyles();
    const classes = useStyles();

    return (
        <Box className={localClasses.mainGrid}>
            <Box className={localClasses.topPane}>
                <Box className={localClasses.logoContainer}>
                    <EnterpriseLogo />
                </Box>
            </Box>
            <Box className={localClasses.leftPane}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={localClasses.logoContainer}>
                        <div>
                            <EnterpriseLogo />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Container maxWidth="sm">
                            <Typography variant="h4" component="h4" className={classes.header}>
                                {welcomeText}
                            </Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Box>
            <Box className={localClasses.rightPane}>
                <Container maxWidth={contentMaxWidth} className={contentClasses?.content}>
                    {contentHeader && (
                        <Typography
                            variant="h3"
                            component="h3"
                            className={localClasses.contentHeader}>
                            {contentHeader}
                        </Typography>
                    )}
                    {children}
                </Container>
            </Box>
        </Box>
    );
};
