/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import axios from 'axios';
import { baseUrl, settingsBaseUrl } from './api';

export interface LoginRequest {
    username: string;
    password: string;
}

export interface LoginResponse {
    success: boolean;
}

export enum AuthenticationUiType {
    USERNAME_PASSWORD = 'USERNAME_PASSWORD',
    USERNAME = 'USERNAME',
    PROTOCOL = 'PROTOCOL',
    EXTERNAL = 'EXTERNAL',
    NONE = 'NONE',
}

export interface LoginConfig {
    type: AuthenticationUiType;
    bannerText?: string;
    companyLogo?: string;
}

export const login = (request: LoginRequest): Promise<LoginResponse> =>
    axios.post(`${baseUrl}/login`, request).then(({ data }) => data);

export const getLoginPageConfig = (): Promise<LoginConfig> => {
    return axios.get(`${baseUrl}/login/config`).then(({ data }) => data);
};

export const checkLoginStatus = (): Promise<number> => {
    return axios.get(`${baseUrl}/login/test`).then(({ status }) => status);
};

export const logout = (): Promise<void> => {
    return axios.get(`${baseUrl}/logout`);
};

export interface LoginSettingsRequest {
    bannerText?: string;
    companyLogo?: string;
}

export interface CustomizeLoginSettings extends LoginSettingsRequest {
    bannerTextSize: number;
    companyLogoSize: number;
    updatedBy?: string;
    updatedAt?: string;
}

export const updateConfig = (request: LoginSettingsRequest): Promise<CustomizeLoginSettings> =>
    axios.post(`${settingsBaseUrl}/login`, request).then(({ data }) => data);

export const getLoginSettingsConfig = (): Promise<CustomizeLoginSettings> => {
    return axios.get(`${settingsBaseUrl}/login`).then(({ data }) => data);
};
