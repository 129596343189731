/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';

import { createUseStyles } from 'react-jss';
import ReactMarkdown from 'react-markdown';
import { paletteSwitch, sqlHighlightStyle } from '../../themes/palette';
import Box from '@mui/material/Box';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { prism, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useThemeMode } from '../../app/UIThemeContextProvider';

interface MarkDownProps {
    text: string;
    className?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
    root: {
        overflowWrap: 'break-word',
    },
    blockQuote: {
        borderLeft: `0.5rem solid ${paletteSwitch(theme).nebulaNavy50}`,
        padding: '0.0625rem 0 0.0625rem 0.5rem',
    },
    code: {
        borderRadius: sqlHighlightStyle.borderRadius,
        backgroundColor: paletteSwitch(theme).sqlHighlightBackground,
        border: `1px solid ${paletteSwitch(theme).sqlHighlightBorder}`,
    },
    paragraph: {
        whiteSpace: 'pre-wrap',
        fontSize: '1rem',
    },
    link: {
        color: paletteSwitch(theme).purple,
        textDecoration: 'none',
    },
}));

const Image = ({ alt, src, title }: { alt?: string; src?: string; title?: string }) => (
    <img alt={alt} src={src} title={title} style={{ maxWidth: '100%' }} />
);

const BlockQuote = ({ children }: { children: React.ReactNode }) => {
    const localStyles = useStyles();
    return <blockquote className={localStyles.blockQuote}>{children}</blockquote>;
};

const Code = ({ children }: { children: React.ReactNode }) => {
    const localStyles = useStyles();
    const themeMode = useThemeMode();
    return (
        <Box className={localStyles.code}>
            <SyntaxHighlighter
                showLineNumbers={false}
                customStyle={{
                    background: 'transparent',
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowWrap: 'break-word',
                }}
                language="sql"
                style={themeMode === 'light' ? prism : a11yDark}
                wrapLines={true}>
                {children}
            </SyntaxHighlighter>
        </Box>
    );
};
const Paragraph = ({ children }: { children: React.ReactNode }) => {
    const localStyles = useStyles();
    return (
        <div className={localStyles.paragraph}>
            <p>{children}</p>
        </div>
    );
};

const Link = ({ href, children }: { href?: string; children?: React.ReactNode }) => {
    const localStyles = useStyles();
    return (
        <a href={href} target="_blank" rel="noreferrer" className={localStyles.link}>
            {children}
        </a>
    );
};

export const MarkDown: React.FunctionComponent<MarkDownProps> = ({ text, className }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            <ReactMarkdown
                components={{
                    img: Image,
                    blockquote: BlockQuote,
                    code: Code,
                    p: Paragraph,
                    a: Link,
                }}>
                {text}
            </ReactMarkdown>
        </div>
    );
};
