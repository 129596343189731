/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import { palette, paletteSwitch } from './palette';

export const useStyles = createUseStyles((theme: Theme) => ({
    inputLabel: {
        backgroundColor: paletteSwitch(theme).main,
    },
    formField: {
        marginTop: theme.spacing(1),
    },
    title: {
        marginLeft: '46px',
    },
    contentBounds: {
        margin: '16px 42px 0 46px',
        paddingBottom: '16px',
    },
    formHeader: {
        fontFamily: 'montserrat, sans-serif',
        fontSize: theme.typography.h6.fontSize,
        marginBottom: theme.spacing(3),
    },
    errorPage: {
        marginTop: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
    header: {
        fontFamily: 'montserrat, sans-serif',
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        color: palette.white,
        fontSize: '32px',
        lineHeight: '48px',
        fontWeight: 600,
        marginLeft: 0,
        letterSpacing: '1px',
    },
    subTitle: {
        fontFamily: 'barlow, sans-serif',
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 400,
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        letterSpacing: '0.25px',
    },
    divider: {
        borderColor: paletteSwitch(theme).nebulaNavy100,
    },
}));
