/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Attribute } from './biac/biacApi';
import { baseUrl, accessControlUrl } from './api';
import axios from 'axios';

export interface ClusterInfo {
    nodeVersion: {
        version: string;
    };
    javaVersion: string;
    environment: string;
    accessControlEnabled: boolean;
    auditEnabled: boolean;
    auditAccessLogEnabled: boolean;
    allQueries: boolean;
    allowedUiFeatures: UiFeatureInfo[];
    dataProductEnabled: boolean;
    dataProductStatsEnabled: boolean;
    persistenceEnabled: boolean;
    uptime: number;
    docsUrl: string;
    user: string;
    metricsPersistenceEnabled: boolean;
    rangerLinks: ExternalLink[];
    troubleshootingBaseUri: string | undefined;
    queryResultsDownloadEnabled: boolean;
    userInactivityTimeoutInMs: number | undefined;
}

interface ExternalLink {
    label: string;
    url: string;
}

export enum UiFeatures {
    SEP_UI = 'SEP_UI',
    QUERY_EDITOR = 'QUERY_EDITOR',
    DATA_PRODUCTS = 'DATA_PRODUCTS',
    OVERVIEW = 'OVERVIEW',
    QUERIES = 'QUERIES',
    CLUSTER_HISTORY = 'CLUSTER_HISTORY',
    USAGE_METRICS = 'USAGE_METRICS',
    SETTINGS = 'SETTINGS',
    SETTINGS_LICENSE = 'SETTINGS_LICENSE',
    SETTINGS_LOGIN = 'SETTINGS_LOGIN',
}

export interface AccessControlInfo {
    user: string;
    accessControlEnabled: boolean;
}

export interface UiFeatureInfo {
    feature: UiFeatures;
    attributes: Attribute[];
    action: string;
    group: boolean;
    displayName: string;
    description: string;
}

export const getClusterInfo = (): Promise<ClusterInfo> => {
    return axios.get(`${baseUrl}/cluster/info`).then((response) => response.data);
};

export const getAccessControlInfo = (): Promise<AccessControlInfo> => {
    return axios.get(`${accessControlUrl}`).then((response) => response.data);
};
