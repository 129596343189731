/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import { paletteSwitch } from '../../themes/palette';
import InputBase from '@mui/material/InputBase';
import { maximumFreeTextLength } from '../../features/dataproduct/util/formUtils';
import { MarkDown } from './MarkDown';
import { createUseStyles } from 'react-jss';
import { MarkDownIconWithDocsLink } from './MarkDownIconWithDocsLink';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    tab: {
        color: paletteSwitch(theme).black54,
        fontWeight: 600,
        cursor: 'pointer',
        minWidth: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem 0',
        '&:hover': {
            color: paletteSwitch(theme).nebulaNavy300,
        },
    },
    activeTab: {
        background: paletteSwitch(theme).white,
        borderTop: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
        borderLeft: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
        borderRight: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
        borderBottom: '1px solid transparent',
        borderRadius: '4px 4px 0 0',
        color: `${paletteSwitch(theme).purple} !important`,
        marginBottom: '-1px',
    },
    inputBase: {
        padding: '1rem 1rem 0.375rem 1rem',
    },
    noContentPreview: {
        margin: '1rem 0 0.25rem 0',
        color: paletteSwitch(theme).black38,
        fontSize: '1rem',
    },
    commentPreviewBox: {
        border: `1px solid ${paletteSwitch(theme).nebulaNavy100}`,
        backgroundColor: paletteSwitch(theme).white,
    },
}));

interface TextFieldWithMarkdownPreviewProps {
    value: string;
    minRows: number;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    placeholder: string;
    previewContentMinHeight: number;
}

enum WritePreviewTabs {
    WRITE = 'Write',
    PREVIEW = 'Preview',
}

export const TextFieldWithMarkdownPreview: React.FunctionComponent<
    TextFieldWithMarkdownPreviewProps
> = ({ value, minRows, onChange, placeholder, previewContentMinHeight }) => {
    const styles = useStyles();

    const [selectedTab, setSelectedTab] = useState<WritePreviewTabs>(WritePreviewTabs.WRITE);

    const handleWrite = () => {
        setSelectedTab(WritePreviewTabs.WRITE);
    };

    const handlePreview = () => {
        setSelectedTab(WritePreviewTabs.PREVIEW);
    };

    return (
        <>
            <Box pt={1.5} pl={1} display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'}>
                    <div
                        className={clsx(styles.tab, {
                            [styles.activeTab]: selectedTab === WritePreviewTabs.WRITE,
                        })}
                        onClick={handleWrite}>
                        {WritePreviewTabs.WRITE}
                    </div>
                    <div
                        className={clsx(styles.tab, {
                            [styles.activeTab]: selectedTab === WritePreviewTabs.PREVIEW,
                        })}
                        onClick={handlePreview}>
                        {WritePreviewTabs.PREVIEW}
                    </div>
                </Box>
                <Box m={1}>
                    <MarkDownIconWithDocsLink />
                </Box>
            </Box>
            <Box mr={1} mb={1.5} ml={1} className={styles.commentPreviewBox}>
                {selectedTab === WritePreviewTabs.WRITE && (
                    <InputBase
                        value={value}
                        minRows={minRows}
                        onChange={onChange}
                        placeholder={placeholder}
                        multiline
                        inputProps={{
                            maxLength: maximumFreeTextLength,
                        }}
                        fullWidth
                        className={styles.inputBase}
                    />
                )}
                {selectedTab === WritePreviewTabs.PREVIEW && (
                    <Box minHeight={previewContentMinHeight} p={'0 1rem'} borderRadius="4px">
                        {value.length ? (
                            <MarkDown text={value} />
                        ) : (
                            <div className={styles.noContentPreview}>No content to preview</div>
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
};
