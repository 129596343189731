/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { navigateBackInHistoryParam } from '../query/details/QueryDetails';
import Box from '@mui/material/Box';

interface QueryDetailsLinkProps {
    queryId?: string;
    queryDone: boolean;
    classes: {
        activeLink: string;
        disabledLink: string;
    };
}

export const QueryDetailsLink: React.FunctionComponent<QueryDetailsLinkProps> = ({
    classes,
    queryDone,
    queryId,
}) => {
    const history = useHistory();

    const onClick = useCallback(() => {
        history.push(`/query/${queryId}`, { [navigateBackInHistoryParam]: true });
    }, [history, queryId]);

    return (
        <Box className={queryDone ? classes.activeLink : classes.disabledLink} onClick={onClick}>
            Query details
        </Box>
    );
};
