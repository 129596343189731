/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from './App';

interface UIThemeContextProviderProps {
    children: ReactElement;
    toggleUITheme: ThemeContextState;
}

interface ThemeContextState {
    toggleUITheme: () => void;
}

export const UIThemeContext = React.createContext<ThemeContextState | null>(null);

export const UIThemeContextProvider: React.FunctionComponent<UIThemeContextProviderProps> = ({
    children,
    toggleUITheme,
}) => {
    return <UIThemeContext.Provider value={toggleUITheme}>{children}</UIThemeContext.Provider>;
};

export const useThemeMode = (): ThemeMode => {
    return useTheme().palette.mode;
};
