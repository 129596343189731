/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import { BannerLayout } from '../BannerLayout';
import { LoginForm } from './LoginForm';
import { LoginConfig, getLoginPageConfig, AuthenticationUiType } from '../../api/loginApi';
import { Spinner } from '../../components/spinner/Spinner';
import { ErrorBox } from '../../components/error/ErrorBox';
import { AppBanner } from '../../components/banner/AppBanner';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { ExternalLogo } from '../../components/logos/ExternalLogo';
import Typography from '@mui/material/Typography';
import { paletteSwitch } from '../../themes/palette';

const useStyles = createUseStyles((theme: Theme) => ({
    externalLogo: {
        textAlign: 'center',
        position: 'relative',
        marginBottom: '3rem',
        zIndex: theme.zIndex.appBar - 1,
    },
    contentHeader: {
        fontFamily: 'montserrat, sans-serif',
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: '29px',
        color: paletteSwitch(theme).nebulaNavy,
    },
}));

interface LoginPageProps {
    redirectPath: string;
}

const ssoPath = '/ui/insights/login/proceed';

export const LoginPage: React.FunctionComponent<LoginPageProps> = ({ redirectPath }) => {
    const classes = useStyles();

    const [loginConfig, setLoginConfig] = useState<LoginConfig | 'loading' | 'error'>('loading');

    useEffect(() => {
        getLoginPageConfig()
            .then((config) => setLoginConfig(config))
            .catch(() => setLoginConfig('error'));
    }, []);

    if (loginConfig === 'loading') {
        return <Spinner position="absolute" />;
    } else if (loginConfig === 'error') {
        return <ErrorBox text={'Could not load login configuration. Please retry later.'} />;
    }

    return (
        <>
            {loginConfig.bannerText && <AppBanner bannerText={loginConfig.bannerText} />}
            <BannerLayout welcomeText="Welcome!">
                {loginConfig.companyLogo && (
                    <Grid item xs={12} className={classes.externalLogo}>
                        <ExternalLogo imgPath={loginConfig.companyLogo} />
                    </Grid>
                )}
                <Typography variant="h3" component="h3" className={classes.contentHeader}>
                    Sign in to your account
                </Typography>
                {[AuthenticationUiType.USERNAME_PASSWORD, AuthenticationUiType.USERNAME].includes(
                    loginConfig.type
                ) && <LoginForm type={loginConfig.type ?? 'NONE'} redirectPath={redirectPath} />}
                {[AuthenticationUiType.PROTOCOL, AuthenticationUiType.EXTERNAL].includes(
                    loginConfig.type
                ) && (
                    <Box display={'flex'} justifyContent={'center'}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                                location.href = ssoPath;
                            }}>
                            {'Sign in with SSO'}
                        </Button>
                    </Box>
                )}
            </BannerLayout>
        </>
    );
};
