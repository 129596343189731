/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import clsx from 'clsx';
import ListItemText from '@mui/material/ListItemText';
import { createUseStyles } from 'react-jss';
import SvgIconComponent from '@mui/material/SvgIcon';
import { paletteSwitch } from '../../themes/palette';
import { Theme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: Theme) => ({
    sidePanelIcon: {
        minWidth: '40px',
        marginLeft: '8px',
        '& .MuiSvgIcon-root': {
            fill: paletteSwitch(theme).black,
        },
    },

    sidePanelBadge: {
        pointerEvents: 'none',
        minWidth: '20px',
    },
    showText: {
        visibility: 'visible',
        transition: '0.1s cubic-bezier(0.4, 0, 1,1)',
    },
    compactNavIcon: {
        padding: '4px 0',
    },
    hideTextForCompactNav: {
        opacity: 0,
        transition: 'opacity 0.1s cubic-bezier(0.4, 0, 1,1)',
    },
    activeIcon: {
        '& .MuiSvgIcon-root': {
            fill: paletteSwitch(theme).nebulaNavy700,
        },
    },
}));

interface SidePanelRawProps {
    id?: string;
    icon?: typeof SvgIconComponent;
    text: string;
    badge?: typeof SvgIconComponent;
    badgeProps?: {
        classes?: {
            root?: string;
        };
    };
    openNav: boolean;
    isSelected: boolean;
    onClick?: () => void;
    className?: string;
}

export const SidePanelItemRaw: React.FunctionComponent<SidePanelRawProps> = ({
    id,
    icon: Icon,
    text,
    badge: BadgeIcon,
    badgeProps,
    openNav,
    isSelected,
    onClick,
    className,
}) => {
    const classes = useStyles();

    return (
        <ListItem
            className={className}
            onClick={onClick}
            id={id}
            selected={isSelected}
            classes={{ selected: classes.activeIcon }}>
            <ListItemIcon
                className={clsx(classes.sidePanelIcon, {
                    [classes.compactNavIcon]: !openNav,
                })}>
                {Icon && <Icon />}
            </ListItemIcon>
            <ListItemText
                primary={text}
                className={clsx(classes.showText, {
                    [classes.hideTextForCompactNav]: !openNav,
                })}
            />
            {BadgeIcon && (
                <ListItemIcon
                    classes={{
                        root: clsx(classes.sidePanelBadge, badgeProps?.classes?.root, {
                            [classes.hideTextForCompactNav]: !openNav,
                        }),
                    }}>
                    <BadgeIcon />
                </ListItemIcon>
            )}
        </ListItem>
    );
};
