/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from '../themes/styles';
import '@fontsource/barlow';
import '@fontsource/barlow/600.css';
import '@fontsource/roboto-condensed/400.css';
import '@fontsource/roboto-condensed/700.css';
import '@fontsource/montserrat';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

interface FontLoaderProps {
    children: React.ReactElement;
}

const FONTS = [
    new FontFaceObserver('Montserrat', { weight: 400 }),
    new FontFaceObserver('Montserrat', { weight: 600 }),
    new FontFaceObserver('Montserrat', { weight: 700 }),
    new FontFaceObserver('Barlow', { weight: 400 }),
    new FontFaceObserver('Barlow', { weight: 600 }),
];
export const FontLoader: React.FunctionComponent<FontLoaderProps> = ({ children }) => {
    const classes = useStyles();
    const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>('loading');

    useEffect(() => {
        Promise.all(FONTS.map((f) => f.load())).then(
            () => setStatus('loaded'),
            () => setStatus('error')
        );
    }, []);

    if (status === 'loading') {
        return (
            <Backdrop open={true} className={classes.backdrop}>
                <CircularProgress />
            </Backdrop>
        );
    } else if (status === 'error') {
        console.error('Could not load font. Falling back to default ones');
    }

    return <>{children}</>;
};
