/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactNode } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import { Theme } from '@mui/material/styles';

interface TooltipProps {
    title: ReactNode;
    children: React.ReactElement;
    placement?: TooltipPlacement;
    delayToolip?: number;
    disableInteractive?: boolean;
}

export type TooltipPlacement =
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

const useStyles = createUseStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: paletteSwitch(theme).nebulaNavy50,
        fontWeight: 400,
        fontSize: '0.875rem',
        color: paletteSwitch(theme).nebulaNavy,
        lineHeight: '20px',
        letterSpacing: '0.252px',
        borderRadius: '0.25rem',
        padding: '0.75rem 1rem',
    },
    arrow: { color: paletteSwitch(theme).nebulaNavy50 },
}));

export const Tooltip: React.FunctionComponent<TooltipProps> = ({
    children,
    title,
    placement,
    delayToolip,
    disableInteractive = false,
}) => {
    const classes = useStyles();

    return (
        <MuiTooltip
            title={title ?? ''}
            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            placement={placement}
            enterNextDelay={delayToolip}
            arrow
            disableInteractive={disableInteractive}>
            {children}
        </MuiTooltip>
    );
};
