/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { FontLoader } from './FontLoader';
import { materialUiTheme } from '../themes/materialUiTheme';
import { materialUiThemeDark } from '../themes/materialUiThemeDark';
import { createGenerateId, JssProvider, ThemeProvider as JssThemeProvider } from 'react-jss';
import { ThemeMode } from './App';

interface ThemingProps {
    children: React.ReactElement;
    themeMode?: ThemeMode;
}
const generateId = createGenerateId();
export const Theming: React.FunctionComponent<ThemingProps> = ({ children, themeMode }) => {
    const currentTheme = themeMode === 'light' ? materialUiTheme : materialUiThemeDark;

    return (
        <StyledEngineProvider injectFirst>
            <JssProvider generateId={generateId}>
                <MuiThemeProvider theme={currentTheme}>
                    <JssThemeProvider theme={currentTheme}>
                        <CssBaseline enableColorScheme />
                        <FontLoader>{children}</FontLoader>
                    </JssThemeProvider>
                </MuiThemeProvider>
            </JssProvider>
        </StyledEngineProvider>
    );
};
