/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { alpha, createTheme, darken } from '@mui/material/styles';
import { darkThemePalette } from './palette';
import { shadows } from './shadows';
import { CSSObject } from '@mui/system';
import { calculateScrollbarWidth } from '../utils/scrollWidth';

const scrollBarStyles: CSSObject = {
    body: {
        scrollbarColor: `${darkThemePalette.scrollbar} ${darkThemePalette.bgLevel4}`,
        scrollbarWidth: 'inherit',
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: darkThemePalette.main,
            width: '0.5rem',
            height: '0.5rem',
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: '16px',
            width: '0.5rem',
            backgroundColor: alpha(darkThemePalette.scrollbar, 0.6),
        },
        '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: darkThemePalette.scrollbar,
        },
        '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: darkThemePalette.scrollbar,
        },
        '&::-webkit-scrollbar:hover, & *::-webkit-scrollbar:hover': {
            backgroundColor: darkThemePalette.bgLevel4,
            width: '0.75rem',
        },
        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: darkThemePalette.main,
        },
    },
};

export const materialUiThemeDark = createTheme({
    typography: {
        fontFamily: 'barlow, Roboto Condensed, sans-serif',
        fontWeightRegular: 400,
        h3: {
            fontFamily: 'montserrat, sans-serif',
            fontSize: '32px',
            fontWeight: 600,
            lineHeight: '48px',
            letterSpacing: '1px',
            color: darkThemePalette.nebulaNavy,
            paddingTop: '2rem',
            paddingBottom: '1.5rem',
        },
        h4: {
            fontFamily: 'montserrat, sans-serif',
            fontSize: '20px',
            fontWeight: 700,
            letterSpacing: '0.15px',
            lineHeight: '30px',
            marginTop: '8px',
            marginLeft: '48px',
            marginBottom: '16px',
            color: darkThemePalette.nebulaNavy,
        },
        h5: {
            fontFamily: 'montserrat, sans-serif',
            fontSize: '18px',
            fontWeight: 600,
            marginBottom: '16px',
            letterSpacing: '0.15px',
            lineHeight: '27px',
            color: darkThemePalette.nebulaNavy,
        },
        subtitle2: {
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.098px',
        },
        body1: {
            color: darkThemePalette.nebulaNavy,
        },
        body2: {
            lineHeight: '24px',
            letterSpacing: '0.14px',
            fontSize: '14px',
        },
    },
    palette: {
        mode: 'dark',
        background: {
            default: darkThemePalette.main,
        },
        primary: {
            dark: darkThemePalette.bgLevel3,
            main: darkThemePalette.purple,
        },
        secondary: {
            main: darkThemePalette.purple,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 800,
            md: 1100,
            lg: 1400,
            xl: 1536,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    letterSpacing: '1.25px',
                    textTransform: 'none',
                    justifyContent: 'normal',
                    whiteSpace: 'nowrap',
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: darken(darkThemePalette.purple, 0.15),
                    },
                    '&.Mui-disabled': {
                        color: darkThemePalette.nebulaNavy400,
                        backgroundColor: darkThemePalette.nebulaNavy50,
                    },
                },
                outlined: {
                    color: darkThemePalette.purple,
                    borderColor: darkThemePalette.nebulaNavy100,
                    '&:hover': {
                        backgroundColor: darkThemePalette.fusionFuchsia025,
                        borderColor: darkThemePalette.purple,
                    },
                    '&.Mui-disabled': {
                        color: darkThemePalette.nebulaNavy400,
                        borderColor: darkThemePalette.nebulaNavy400,
                    },
                },
                textSecondary: {
                    color: darkThemePalette.error,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    '&.Mui-error': {
                        color: darkThemePalette.error,
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '14px',
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    letterSpacing: '0.15px',
                    '&.Mui-error': {
                        color: darkThemePalette.error,
                        marginLeft: 0,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: darkThemePalette.nebulaNavy500,
                    },
                    '&.Mui-error': {
                        color: darkThemePalette.error,
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionEnd: {
                    marginRight: '1rem',
                    color: darkThemePalette.error,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& fieldset': {
                        border: `1px solid ${darkThemePalette.nebulaNavy100}`,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: `${darkThemePalette.info} !important`,
                    },
                    '&.Mui-error fieldset': {
                        borderColor: `${darkThemePalette.error}!important`,
                    },
                    paddingRight: 0,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: darkThemePalette.bgLevel3,
                    '&.MuiAlert-standardError': {
                        background: darkThemePalette.errorLight,
                        border: `1px solid ${darkThemePalette.errorDark}`,
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        color: darkThemePalette.errorDark,
                        fontSize: '14px',
                        lineHeight: '24px',
                        '.MuiAlert-icon': {
                            color: darkThemePalette.errorDark,
                            marginTop: '0.125rem',
                        },
                    },
                    '&.MuiAlert-standardSuccess': {
                        background: darkThemePalette.successLight,
                        border: `1px solid ${darkThemePalette.successDark}`,
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        color: darkThemePalette.successDark,
                        fontSize: '14px',
                        lineHeight: '24px',
                        '.MuiAlert-icon': {
                            color: darkThemePalette.successDark,
                            marginTop: '0.125rem',
                        },
                    },
                    '&.MuiAlert-standardInfo': {
                        background: darkThemePalette.infoLight,
                        border: `1px solid ${darkThemePalette.infoDark}`,
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        color: darkThemePalette.infoDark,
                        fontSize: '14px',
                        lineHeight: '24px',
                        '.MuiAlert-icon': {
                            color: darkThemePalette.infoDark,
                            marginTop: '0.125rem',
                        },
                    },
                    '&.MuiAlert-standardWarning': {
                        background: darkThemePalette.warningLight,
                        border: `1px solid ${darkThemePalette.warningDark}`,
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        color: darkThemePalette.warningDark,
                        lineHeight: '24px',
                        fontSize: '14px',
                        '.MuiAlert-icon': {
                            color: darkThemePalette.warningDark,
                            marginTop: '0.125rem',
                        },
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: shadows[1],
                    backgroundColor: darkThemePalette.bgLevel3,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: darkThemePalette.nebulaNavy50,
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    color: darkThemePalette.nebulaNavy,
                    lineHeight: '20px',
                    letterSpacing: '0.252px',
                    borderRadius: '0.25rem',
                    padding: '0.75rem 1rem',
                },
                arrow: {
                    color: darkThemePalette.nebulaNavy50,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    '&.Mui-checked': {
                        '&$disabled': {
                            color: darkThemePalette.fusionFuchsia100,
                        },
                        '&$disabled + $track': {
                            opacity: '0.5',
                            backgroundColor: darkThemePalette.fusionFuchsia050,
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${darkThemePalette.nebulaNavy50}`,
                    color: darkThemePalette.nebulaNavy,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    ':hover': {
                        backgroundColor: `${darkThemePalette.nebulaNavy15} !important`,
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
                color: darkThemePalette.purple,
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: `${darkThemePalette.nebulaNavy15} !important`,
                        color: darkThemePalette.nebulaNavy,
                    },
                    '&.Mui-selected': {
                        backgroundColor: `${darkThemePalette.nebulaNavy50} !important`,
                        color: darkThemePalette.nebulaNavy,
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    boxSizing: 'border-box',
                    '& ul': {
                        padding: 0,
                        margin: 0,
                    },
                },
                option: {
                    '&.Mui-focused': {
                        backgroundColor: `${darkThemePalette.nebulaNavy15} !important`,
                        color: darkThemePalette.nebulaNavy,
                    },
                    '&[aria-selected="true"]': {
                        backgroundColor: `${darkThemePalette.nebulaNavy50} !important`,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: darkThemePalette.nebulaNavy300,
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    backgroundColor: 'unset',
                    paddingLeft: '24px  !important',
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    backgroundColor: 'unset',
                    '&.MuiPickersDay-root': {
                        backgroundColor: 'unset',
                        '&:focus': {
                            '&.Mui-selected': {
                                backgroundColor: darkThemePalette.purple,
                            },
                        },
                    },
                    '&.MuiTabScrollButton-root': {
                        '&.Mui-disabled': {
                            opacity: 0.3,
                        },
                    },
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    background: darkThemePalette.bgLevel3,
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                expandIconWrapper: {
                    color: darkThemePalette.purple,
                },
            },
        },
        MuiCssBaseline: calculateScrollbarWidth() > 0 ? { styleOverrides: scrollBarStyles } : {},
    },
});
