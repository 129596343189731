/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { DatasetType, MaterializedView, View } from '../../../../../api/dataProduct/dataProductApi';
import { NoData } from '../../../../../components/error/NoData';
import { paletteSwitch } from '../../../../../themes/palette';
import { DatasetDetailsEntry } from './DatasetDetailsEntry';
import { ViewMetadata } from './ViewMetadata';
import { MaterializedViewMetadata } from './MaterializedViewMetadata';
import { DatasetFilterButton } from './DatasetFilterButton';
import { Theme } from '@mui/material/styles';

interface DatasetDetailsProps {
    dataProductId: string;
    catalogName: string;
    schemaName: string;
    views: View[];
    materializedViews: MaterializedView[];
}

const useStyles = createUseStyles((theme: Theme) => ({
    datasetSection: {
        marginTop: '-1rem',
        marginRight: '1rem',
    },
    filterInfo: {
        fontSize: '0.875rem',
        fontWeight: 400,
        padding: '1.5rem 1.5rem 1.5rem 0',
        color: paletteSwitch(theme).black54,
    },
    filter: {
        padding: '1.5rem 1rem 1.5rem 0',
        marginLeft: 'auto',
    },
    viewButton: {
        backgroundColor: paletteSwitch(theme).purple,
        '&:hover': {
            backgroundColor: paletteSwitch(theme).purple,
        },
    },
    viewButtonInactive: {
        color: paletteSwitch(theme).purple,
        borderColor: paletteSwitch(theme).purple,
        '&:hover': {
            borderColor: paletteSwitch(theme).purple,
            backgroundColor: paletteSwitch(theme).opaqueLightGrey,
        },
        minWidth: '5px',
    },
    materialViewButton: {
        backgroundColor: paletteSwitch(theme).teal,
        '&:hover': {
            backgroundColor: paletteSwitch(theme).teal,
        },
    },
    materialViewButtonInactive: {
        borderColor: paletteSwitch(theme).teal,
        color: paletteSwitch(theme).teal,
        '&:hover': {
            borderColor: paletteSwitch(theme).teal,
            backgroundColor: paletteSwitch(theme).opaqueLightGrey,
        },
    },
    rowFlex: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerBorder: {
        borderBottom: `1px solid ${paletteSwitch(theme).black12}`,
    },
    nodata: {
        fontSize: '0.75rem',
        color: paletteSwitch(theme).black54,
        textAlign: 'center',
        paddingTop: '1rem',
    },
}));

export const DatasetDetails: React.FunctionComponent<DatasetDetailsProps> = ({
    dataProductId,
    catalogName,
    schemaName,
    views,
    materializedViews,
}) => {
    const classes = useStyles();
    const datasetsCount = views.length + materializedViews.length;
    const [isViewVisible, setIsViewVisible] = useState<boolean>(true);
    const [isMaterializedViewVisible, setIsMaterializedViewVisible] = useState<boolean>(true);
    const alreadyUsedViewNames = useMemo<string[]>(
        () => views.map(({ name }) => name).concat(materializedViews.map(({ name }) => name)),
        [views, materializedViews]
    );

    const calculateFilteredDatasetCount = () => {
        let count = 0;
        if (isViewVisible) {
            count = count + views.length;
        }
        if (isMaterializedViewVisible) {
            count = count + materializedViews.length;
        }
        return count;
    };

    return (
        <div className={classes.datasetSection}>
            {datasetsCount === 0 ? (
                <NoData height={130} icon={'table'} />
            ) : (
                <div>
                    <div className={clsx(classes.rowFlex, classes.headerBorder)}>
                        <div className={classes.filterInfo}>
                            Showing <b>{calculateFilteredDatasetCount()}</b> of{' '}
                            <b>{datasetsCount}</b> datasets
                        </div>
                        <div className={classes.filter}>
                            <DatasetFilterButton
                                className={clsx({
                                    [classes.viewButton]: isViewVisible,
                                    [classes.viewButtonInactive]: !isViewVisible,
                                })}
                                handleOnClick={() => setIsViewVisible(!isViewVisible)}
                                isContained={isViewVisible}
                                label="VIEWS"
                            />
                            <DatasetFilterButton
                                className={clsx({
                                    [classes.materialViewButton]: isMaterializedViewVisible,
                                    [classes.materialViewButtonInactive]:
                                        !isMaterializedViewVisible,
                                })}
                                isContained={isMaterializedViewVisible}
                                handleOnClick={() =>
                                    setIsMaterializedViewVisible(!isMaterializedViewVisible)
                                }
                                label="MATERIALIZED VIEWS"
                            />
                        </div>
                    </div>
                    {calculateFilteredDatasetCount() === 0 && (
                        <div className={classes.nodata}>No datasets match your filters</div>
                    )}
                    {isViewVisible &&
                        views.map((view) => (
                            <DatasetDetailsEntry
                                key={view.name}
                                dataProductId={dataProductId}
                                catalogName={catalogName}
                                schemaName={schemaName}
                                dataset={view}
                                metadata={<ViewMetadata updatedAt={view.updatedAt} />}
                                alreadyUsedViewNames={alreadyUsedViewNames}
                                type={DatasetType.VIEW}
                            />
                        ))}
                    {isMaterializedViewVisible &&
                        materializedViews.map((view) => (
                            <DatasetDetailsEntry
                                key={view.name}
                                dataProductId={dataProductId}
                                catalogName={catalogName}
                                schemaName={schemaName}
                                dataset={view}
                                metadata={
                                    <MaterializedViewMetadata
                                        dataProductId={dataProductId}
                                        viewName={view.name}
                                        viewStatus={view.status}
                                    />
                                }
                                alreadyUsedViewNames={alreadyUsedViewNames}
                                type={DatasetType.MATERIALIZED_VIEW}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};
