/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import InputMask from 'react-input-mask';
import { format, isValid, parse } from 'date-fns';
import { createUseStyles } from 'react-jss';
import { paletteSwitch } from '../../themes/palette';
import { Theme } from '@mui/material/styles';

interface Time {
    text: string | null;
    ampm: 'AM' | 'PM';
}

interface TimePicker12hProps {
    value: Date;
    onValueChange: (newValue: Date, valid: boolean) => void;
    defaultTime: Date;
    timeDisabled: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
    separator: {
        color: paletteSwitch(theme).black54,
        fontWeight: 500,
        marginTop: '-2px',
        marginRight: '2px',
        marginLeft: '2px',
    },
    disabledSeparator: {
        color: paletteSwitch(theme).black20,
    },
    activeAmpm: {
        minWidth: 'initial',
        color: paletteSwitch(theme).purple,
    },
    inactiveAmpm: {
        minWidth: 'initial',
        color: paletteSwitch(theme).black54,
    },
}));

export const TimePicker12h: React.FunctionComponent<TimePicker12hProps> = ({
    value,
    defaultTime,
    timeDisabled,
    onValueChange,
}) => {
    const classes = useStyles();

    const parseTime = (date: Date = value): Time => {
        if (isValid(date)) {
            const timeValue = format(date, 'hh:mm');
            const ampm = format(date, 'a') as 'AM' | 'PM';
            return {
                text: timeValue,
                ampm,
            };
        } else {
            return parseTime(defaultTime);
        }
    };

    const [time, setTime] = useState<Time>(parseTime());

    const formatTime = useCallback((time: Time, base = new Date()): Date => {
        const format = 'hh:mm a';
        const text = `${time.text || ''} ${time.ampm}`;
        return parse(text, format, base);
    }, []);

    const timeValid = useCallback((time: Time): boolean => {
        if (time.text === null) {
            return false;
        }

        try {
            const resultTime = formatTime(time);
            return isValid(resultTime);
        } catch (_) {
            return false;
        }
        // eslint-disable-next-line
  }, []);

    useEffect(() => {
        if (!timeDisabled) {
            onValueChange(formatTime(time), timeValid(time));
        }
    }, [timeDisabled]);

    const notifyTimeChange = (time: Time) => {
        if (timeValid(time)) {
            const date = formatTime(time);
            onValueChange(date, true);
        } else {
            onValueChange(defaultTime, false);
        }
    };

    const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newTime = {
            text: event.target.value,
            ampm: time.ampm,
        };

        setTime(newTime);
        notifyTimeChange(newTime);
    };

    const onAmpmChange = (ampm: 'AM' | 'PM') => (): void => {
        const newTime = {
            text: time.text,
            ampm: ampm,
        };

        setTime(newTime);
        notifyTimeChange(newTime);
    };

    return (
        <InputMask
            mask="99:99"
            value={timeDisabled ? '' : time?.text || ''}
            onChange={onTimeChange}
            alwaysShowMask={true}
            disabled={timeDisabled}>
            {(): ReactElement<HTMLElement> => (
                <TextField
                    disabled={timeDisabled}
                    error={!timeValid(time)}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={onAmpmChange('AM')}
                                    disabled={timeDisabled}
                                    className={
                                        time.ampm === 'AM'
                                            ? classes.activeAmpm
                                            : classes.inactiveAmpm
                                    }>
                                    AM
                                </Button>
                                <span
                                    className={clsx(classes.separator, {
                                        [classes.disabledSeparator]: timeDisabled,
                                    })}>
                                    /
                                </span>
                                <Button
                                    variant="text"
                                    size="small"
                                    disabled={timeDisabled}
                                    className={
                                        time.ampm === 'PM'
                                            ? classes.activeAmpm
                                            : classes.inactiveAmpm
                                    }
                                    onClick={onAmpmChange('PM')}>
                                    PM
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    margin="dense"
                />
            )}
        </InputMask>
    );
};
