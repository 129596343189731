/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext, useState } from 'react';

import { createUseStyles } from 'react-jss';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ConfigContext } from '../app/ConfigContextProvider';

const useStyles = createUseStyles({
    helpButton: {
        transition: 'opacity 0.3s',
        '&:hover': {
            opacity: 0.8,
        },
    },
});

export const HelpMenu: React.FunctionComponent = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const configContext = useContext(ConfigContext);
    const docsUrl = configContext?.docsUrl + '/overview/sep-ui.html';
    const rangerLinks = configContext?.rangerLinks ?? [];
    const handleMenu = (ev: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(ev.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                data-qa="help-button"
                color="inherit"
                className={classes.helpButton}
                aria-label="help"
                onClick={handleMenu}
                size="large">
                <HelpIcon />
            </IconButton>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                keepMounted={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                {rangerLinks.map((link) => (
                    <MenuItem
                        key={link.url}
                        onClick={() => {
                            handleClose();
                            window.open(link.url, '_blank');
                        }}>
                        {link.label}
                    </MenuItem>
                ))}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        window.open('/ui', '_blank');
                    }}>
                    Trino UI
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        window.open(docsUrl, '_blank');
                    }}>
                    Documentation
                </MenuItem>
            </Menu>
        </>
    );
};
