/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '../../useQueryClient';
import sortBy from 'lodash/sortBy';
import capitalize from 'lodash/capitalize';
import { EmptyOrValue } from '../../../../utils/value';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { ChooserErrorIcon } from '../../grants/ChooserErrorIcon';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Checkbox from '@mui/material/Checkbox';
import { addPrivilegesStyles } from '../add-privileges-styles';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import clsx from 'clsx';
import { EntityCategory } from '../../../../api/biac/biacApi';
import { PopperUnstyledProps } from '@mui/base/PopperUnstyled';
import Popper from '@mui/material/Popper';
import Paper, { PaperProps } from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { VirtualizedListBoxComponent } from '../../grants/VirtualizedListBoxComponent';
import { Tooltip } from '../../../../components/tooltip/Tooltip';

interface SessionPropertiesSelectorProps {
    autocomplete: boolean;
    disabled: boolean;
    catalogName: string | null;
    value: EmptyOrValue<string[] | null>;
    handleChange: (value: EmptyOrValue<string[] | null>) => void;
    entityCategory: EntityCategory;
}

export interface PropertyInfo {
    label: string;
    propertyName: string;
    description: string;
}

export const SessionPropertiesSelector: React.FunctionComponent<SessionPropertiesSelectorProps> = ({
    autocomplete,
    disabled,
    catalogName,
    value,
    handleChange,
    entityCategory,
}) => {
    const classes = addPrivilegesStyles();
    const [properties, setProperties] = useState<PropertyInfo[]>([]);
    const [selectedProperties, setSelectedProperties] = useState<PropertyInfo[]>([]);
    const allPropertiesChecked = !value.empty && value.value === null;

    const { busy, error, execute, reset } = useQueryClient((data) => {
        const columns = sortBy(
            data.map<PropertyInfo>(([name, , , , description]) => ({
                label: name as string,
                propertyName: name as string,
                description: capitalize(description as string),
            })),
            ({ label }) => label
        );
        setProperties([...columns]);
    });

    const filteredProperties = useMemo(() => {
        return properties.filter(({ propertyName }) =>
            catalogName ? propertyName.startsWith(`${catalogName}.`) : !propertyName.includes('.')
        );
    }, [properties, catalogName]);
    const label = useMemo(() => {
        return (
            `${entityCategory === 'system_session_properties' ? 'system' : 'catalog'}` +
            ' session properties'
        );
    }, [entityCategory]);

    const propertiesSelectorLabel = useMemo(() => {
        if (catalogName && !busy && filteredProperties.length === 0) {
            return `No ${label} available`;
        } else {
            return label;
        }
    }, [catalogName, busy, filteredProperties]);

    useEffect(() => {
        if (!disabled && autocomplete) {
            execute('SHOW SESSION');
            return () => {
                setProperties([]);
                reset();
            };
        }
    }, [disabled]);

    useEffect(() => {
        setSelectedProperties([]);
        handleChange({ empty: true, value: null });
    }, [catalogName]);

    useEffect(() => {
        const selectedLength = value.value?.filter((currentVal) =>
            selectedProperties.some(
                (selectedProperty) => currentVal === selectedProperty.propertyName
            )
        ).length;
        if (!selectedLength) {
            if (value.value) {
                const propertiesFound: PropertyInfo[] = [];
                properties.forEach((propertyFound) => {
                    value.value?.forEach((val) => {
                        if (propertyFound.propertyName === val) {
                            propertiesFound.push(propertyFound);
                        }
                    });
                });
                setSelectedProperties(propertiesFound ? propertiesFound : []);
            } else {
                setSelectedProperties([]);
            }
        }
    }, [value]);

    useEffect(() => {
        return () => handleChange({ empty: true, value: null });
    }, []);

    const onChange = useCallback((event: React.SyntheticEvent, value: PropertyInfo[]) => {
        setSelectedProperties(value);
        setValues(value);
    }, []);
    const setValues = (value: PropertyInfo[]) => {
        if (!value.length) {
            handleChange({
                empty: true,
                value: null,
            });
        } else {
            handleChange({
                empty: !value,
                value: value.map((val) => val.propertyName),
            });
        }
    };

    const renderOption = useCallback(
        (props: React.HTMLAttributes<HTMLLIElement>, option: PropertyInfo, { selected }) => {
            return (
                <li {...props}>
                    <Checkbox style={{ marginRight: '8px' }} checked={selected} />
                    <Tooltip
                        title={
                            <div>
                                <div>{option.label}</div>
                                {option.description && (
                                    <div className={classes.toolTipOptionDescription}>
                                        {option.description}
                                    </div>
                                )}
                            </div>
                        }
                        placement="right"
                        disableInteractive
                        delayToolip={1000}>
                        <div>
                            <div className={classes.optionLabel}>{option.label}</div>
                            {option.description && (
                                <div className={classes.optionDescription}>
                                    {option.description}
                                </div>
                            )}
                        </div>
                    </Tooltip>
                </li>
            );
        },
        []
    );
    const renderPopupIcon = useCallback(() => {
        if (busy) {
            return <CircularProgress size={20} />;
        } else if (error) {
            return (
                <div>
                    <ChooserErrorIcon title={`Loading ${label} failed`} />
                </div>
            );
        } else {
            return <ArrowDropDownOutlinedIcon />;
        }
    }, [busy, error]);

    const handleCheckStar = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            handleChange({
                empty: false,
                value: null,
            });
            setSelectedProperties([]);
        } else {
            handleChange({
                empty: true,
                value: null,
            });
        }
    }, []);

    const checkAllChange = useCallback(() => {
        setValues(filteredProperties);
        setSelectedProperties(filteredProperties);
    }, [filteredProperties]);

    const SelectAllButton = useCallback(
        (param: PopperUnstyledProps) => (
            <Popper {...param}>
                <Box {...param} />
                <Paper className={classes.popperComponentPaper}>
                    <Box justifyContent="flex-end" display="flex">
                        <Button
                            variant="outlined"
                            size="small"
                            onMouseDown={(e) => {
                                e.preventDefault();
                            }}
                            onClick={checkAllChange}>
                            Select all functions
                        </Button>
                    </Box>
                </Paper>
            </Popper>
        ),
        [checkAllChange]
    );
    const CustomPaper = useCallback(
        (props: PaperProps) => <Paper style={{ borderRadius: '4px 4px 0 0' }} {...props} />,
        []
    );

    return (
        <Grid pl={1} maxWidth="fit-content">
            <Typography variant="h5">Which {label} would you like to select?</Typography>
            <Typography className={classes.questionInfo}>
                Use the dropdown to select the {label}. To select all currently defined {label}, as
                well as {label} defined in the future, check the <b>All {label}</b> box instead.
            </Typography>
            <Box mb={1.5}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allPropertiesChecked}
                            onChange={handleCheckStar}
                            id="select-star"
                            disabled={disabled}
                        />
                    }
                    label={<span>All {label}</span>}
                />
            </Box>
            <Box mb={2}>
                <Autocomplete
                    value={selectedProperties}
                    multiple
                    ListboxComponent={VirtualizedListBoxComponent}
                    limitTags={1}
                    clearText="Clear selected properties"
                    id="multiple-limit-tags"
                    options={filteredProperties}
                    popupIcon={renderPopupIcon()}
                    renderOption={renderOption}
                    onChange={onChange}
                    disabled={allPropertiesChecked || disabled || !filteredProperties.length}
                    fullWidth
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    disableCloseOnSelect
                    ChipProps={{
                        className: clsx(classes.chip, classes.maxWidthChip),
                        deleteIcon: <CloseIcon className={classes.chipCloseIcon} />,
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={capitalize(propertiesSelectorLabel)} />
                    )}
                    PopperComponent={SelectAllButton}
                    PaperComponent={CustomPaper}
                />
            </Box>
        </Grid>
    );
};
